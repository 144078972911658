const config = {
  //REACT_APP_PORT: "8080",
  REACT_APP_FRONT_URL: "https://tools.ptahini.ru",
  REACT_APP_SERVER_URL: "https://tools.ptahini.ru",
  REACT_APP_FRONT_URL_VKID: "https://tools.ptahini.ru",
  debug: false,
  ROBOKASSA_PASSWORD_1: "JSjiRusR38kBUp43GYY1",
  ROBOKASSA_SHOP_NAME: "SEOPtahini",
  ROBOKASSA_TEST: "0",
};

module.exports = config;
