import React, { useState } from "react";
import s from "./../BalancePage.module.css";

// Компонент Вопроса
const Question = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false); // Используем состояние для открытия/закрытия ответа

  return (
    <div className={s.faq}>
      {/* Вопрос */}
      <div className={s.question} onClick={() => setIsOpen(!isOpen)}>
        {question}
      </div>
      {/* Ответ будет отображаться только если isOpen === true */}
      {isOpen && (
        <div
          className={s.contentOtvet}
          dangerouslySetInnerHTML={{ __html: answer }} // Используем dangerouslySetInnerHTML для вставки HTML
        />
      )}
    </div>
  );
};

// Компонент для отображения всех вопросов и ответов
export default function WhatLvt(props) {
  return (
    <div>
      {/* Используем компонент Вопроса для каждого вопроса */}
      <Question
        question="1) Что такое Lvt запросы?"
        answer="<p>Lvt - это внутренняя платёжная единица системы Ptahini, которая списывается с баланса, согласно указанного тарифа использованного инструмента.</p>"
      />
      <Question
        question="2) Как работают Lvt запросы?"
        answer="<p>Lvt запросы работают путем списания определенной суммы с баланса согласно тарифу, установленному для каждого инструмента.</p>"
      />
      <Question
        question="3) Как можно получить Lvt бесплатно?"
        answer="<p>Lvt можно получить бесплатно разными способами. Например вам каждый день начисляются 3 lvt. Самый лучший способ - это участие в реферальной программе, вы будете получать 15% от суммы пополнений вашего реферала.</p><table><tr><td>3 Lvt</td><td>Ежедневный 9:00 МСК</td></tr><tr><td>20 Lvt</td><td>за регистрацию</td></tr><tr><td>50 Lvt</td><td>за рекомендацию</td></tr><tr><td>50 Lvt</td><td>за репост</td></tr><tr><td>15% Lvt</td><td>реферальная про</td></tr></table>"
      />
      <Question
        question="4) Как получить реферальный бонус?"
        answer="<p>Вы можете получить рефереальную выплату, если по вашей <a href='/referal/'>партнерской ссылке</a> зарегистрируется пользователь. И тогда с каждого пополнения баланса вашего реферала, вы <strong>будете получать 15%</strong> от суммы его пополения. Вы получите об этом оповещение и ваш баланс пополнится.</p>"
      />
      <Question
        question="5) Как купить Lvt запросы по самой низкой цене?"
        answer="<p>Система покупки Lvt устроенна таким образом, что <strong>при единоразовой покупке большого кол-ва Lvt вы получаете в подарок</strong> определённое кол-во Lvt бонусом. Если кратко, чем больше-тем и выгодней.</p>"
      />
      <Question
        question="6) Как получить бонус за рекомендацию или репост в соц.сети"
        answer="<p>Здесь вам придется подождать сутки. Нужно чтобы репост провисел на стене более 24 часов. После этого пришлите нам ссылку на пост на адрес app@ptahini.ru с указанием вашего логина. Мы начислим вам 50 Lvt от администрации.</p>"
      />
      {/* Добавьте больше вопросов и ответов при необходимости */}
    </div>
  );
}
